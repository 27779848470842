import { Component, HostBinding, TemplateRef } from '@angular/core';

import { ToastService } from '../../services/toast.service';

@Component({
	selector: 'app-toasts',
	templateUrl: 'toasts-container.component.html',
	styleUrls: ['./toasts-container.component.scss']
})
export class ToastsContainerComponent {
	@HostBinding('class') class = 'ngb-toasts';
	constructor(public toastService: ToastService) {}
	isTemplate(toast) {
		return toast.textOrTpl instanceof TemplateRef;
	}

	close(toast) {
		toast.show = false;
		this.toastService.remove(toast);
	}
}
