// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const config = {
	STRIPE_KEY: 'pk_test_v1amvR35uoCNduJfkqGB8RLD',
	s3Bucket: {
		REGION: 'us-east-1',
		BUCKET: ''
	},
	apiGateway: {
		REGION: 'us-east-1',
		URL: 'https://dev-api.pario.io'
	},
	cognito: {
		REGION: 'us-east-1',
		USER_POOL_ID: 'us-east-1_HChzvpape',
		APP_CLIENT_ID: '31s53snppbg1uv4hfk0t18hjlm',
		IDENTITY_POOL_ID: 'us-east-1:9dc10272-4b0f-403d-b913-c725a2960129'
	}
};

export const environment = {
	production: true,
	CDN_BUCKET: 'dev-cdn.pario.io',
	ACTIONS_BUCKET: 'pario-uploads-dev-s3bucket-16mudqv17s2tr',
	UNSUBSCRIBE_URL: 'https://dev-links.pario.io/unsubscribe',
	webflow: 'd950a51cb8d6101d225c72617a889eeb2773004dce5165edffba4ad2e4ef74ff',
	confirm: {
		email: '',
		password: ''
	},
	amplify: {
		Auth: {
			mandatorySignIn: true,
			region: config.cognito.REGION,
			userPoolId: config.cognito.USER_POOL_ID,
			identityPoolId: config.cognito.IDENTITY_POOL_ID,
			userPoolWebClientId: config.cognito.APP_CLIENT_ID
		},
		Storage: {
			AWSS3: {
				bucket: '',
				region: config.s3Bucket.REGION,
				identityPoolId: config.cognito.IDENTITY_POOL_ID
			},
			s3Bucket: {
				region: config.s3Bucket.REGION,
				bucket: config.s3Bucket.BUCKET,
				identityPoolId: config.cognito.IDENTITY_POOL_ID
			}
		},
		API: {
			endpoints: [
				{
					name: 'clients',
					endpoint: `${config.apiGateway.URL}`,
					region: config.apiGateway.REGION
				},
				{
					name: 'campaigns',
					endpoint: `${config.apiGateway.URL}`,
					region: config.apiGateway.REGION
				},
				{
					name: 'lists',
					endpoint: `${config.apiGateway.URL}`,
					region: config.apiGateway.REGION
				},
				{
					name: 'records',
					endpoint: `${config.apiGateway.URL}`,
					region: config.apiGateway.REGION
				},
				{
					name: 'templates',
					endpoint: `${config.apiGateway.URL}`,
					region: config.apiGateway.REGION
				},
				{
					name: 'actions',
					endpoint: `${config.apiGateway.URL}`,
					region: config.apiGateway.REGION
				},
				{
					name: 'variables',
					endpoint: `${config.apiGateway.URL}`,
					region: config.apiGateway.REGION
				}
			]
		}
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
