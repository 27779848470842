import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { from } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ClientService {
	constructor() {}

	// Client API
	getClients() {
		return from(API.get('clients', `/clients`, {}));
	}
	getClient(id: string) {
		return from(API.get('clients', `/client/${id}`, {}));
	}
	createClient(client: any) {
		return from(
			API.post('clients', '/client', {
				body: client
			})
		);
	}
	updateClient(client: any) {
		return from(API.put('clients', `/client/${client.id}`, { body: client }));
	}
	removeClient(id: string) {
		return from(API.del('clients', `/client/${id}`, {}));
	}
}
