export const replaceAccents = (str) => {
	// verifies if the String has accents and replace them
	if (str.search(/[\xC0-\xFF]/g) > -1) {
		str = str
			.replace(/[\xC0-\xC5]/g, 'A')
			.replace(/[\xC6]/g, 'AE')
			.replace(/[\xC7]/g, 'C')
			.replace(/[\xC8-\xCB]/g, 'E')
			.replace(/[\xCC-\xCF]/g, 'I')
			.replace(/[\xD0]/g, 'D')
			.replace(/[\xD1]/g, 'N')
			.replace(/[\xD2-\xD6\xD8]/g, 'O')
			.replace(/[\xD9-\xDC]/g, 'U')
			.replace(/[\xDD]/g, 'Y')
			.replace(/[\xDE]/g, 'P')
			.replace(/[\xE0-\xE5]/g, 'a')
			.replace(/[\xE6]/g, 'ae')
			.replace(/[\xE7]/g, 'c')
			.replace(/[\xE8-\xEB]/g, 'e')
			.replace(/[\xEC-\xEF]/g, 'i')
			.replace(/[\xF1]/g, 'n')
			.replace(/[\xF2-\xF6\xF8]/g, 'o')
			.replace(/[\xF9-\xFC]/g, 'u')
			.replace(/[\xFE]/g, 'p')
			.replace(/[\xFD\xFF]/g, 'y');
	}
	return str;
};

export const removeNonWord = (str) => {
	const PATTERN = /[^\x20\x2D0-9A-Z\x5Fa-z\xC0-\xD6\xD8-\xF6\xF8-\xFF]/g;
	return str.replace(PATTERN, '').replace(/\s/g, '');
};

export const formatText = (str) => {
	return removeNonWord(str);
};

export const sortByCreationDate = (a, b) => (a.created < b.created ? 1 : a.created > b.created ? -1 : 0);
export const sortByUpdateDate = (a, b) => (a.updated < b.updated ? 1 : a.updated > b.updated ? -1 : 0);
export const sortByIndexValue = (a, b) => a.indexValue - b.indexValue;
