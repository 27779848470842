import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface AuthState {
	isLoggedIn: boolean;
	username: string | null;
	id: string | null;
	email: string | null;
}

const initialAuthState = {
	isLoggedIn: false,
	username: null,
	id: null,
	email: null
};

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private readonly _authState = new BehaviorSubject<AuthState>(initialAuthState);

	/** AuthState as an Observable */
	readonly auth$ = this._authState.asObservable();

	/** Observe the isLoggedIn slice of the auth state */
	readonly isLoggedIn$ = this.auth$.pipe(map((state) => state.isLoggedIn));

	constructor() {
		// Get the user on creation of this service
		Auth.currentAuthenticatedUser().then(
			(user: any) => this.setUser(user),
			(_err) => this._authState.next(initialAuthState)
		);

		// Use Hub channel 'auth' to get notified on changes
		Hub.listen('auth', ({ payload: { event, data, message } }) => {
			if (event === 'signIn') {
				// On 'signIn' event, the data is a CognitoUser object
				this.setUser(data);
			} else {
				this._authState.next(initialAuthState);
			}
		});
	}

	private setUser(user: any) {
		if (!user || !user.attributes) {
			return;
		}
		const {
			attributes: { sub: id, email },
			username
		} = user;
		this._authState.next({ isLoggedIn: true, id, username, email });
	}

	newPassword(cognitoUser: any, newPassword: string, reqAttributes: any) {
		Auth.completeNewPassword(
			cognitoUser, // the Cognito User Object
			newPassword, // the new password
			// OPTIONAL, the required attributes
			reqAttributes
		)
			.then((user) => {
				// console.log('NEW_PASSWORD: ', user);
				Auth.currentAuthenticatedUser().then(
					(currentUser: any) => this.setUser(currentUser),
					(_err) => this._authState.next(initialAuthState)
				);
			})
			.catch((error: any) => console.log('ERROR: ', error));
	}

	signIn(username: string, password: string): Promise<CognitoUser | any> {
		return new Promise((resolve, reject) => {
			Auth.signIn(username, password)
				.then((user: CognitoUser | any) => {
					resolve(user);
				})
				.catch((error: any) => reject(error));
		});
	}

	signOut(): Promise<any> {
		return Auth.signOut().then(() => this._authState.next(initialAuthState));
	}
}

// signUp(user: NewUser): Promise<CognitoUser|any> {
//   return Auth.signUp({
//     'username': user.email,
//     'password': user.password,
//     'attributes': {
//       'email': user.email,
//       'given_name': user.firstName,
//       'family_name': user.lastName,
//       'phone_number': user.phone
//     }
//   });
// }

// import { Injectable } from '@angular/core';
// import { BehaviorSubject, bindNodeCallback } from 'rxjs';
// import * as auth0 from 'auth0-js';
// import { environment } from '../../../environments/environment';
// import { Router } from '@angular/router';
// // import { Auth } from 'aws-amplify';
// // import * as AWS from 'aws-sdk';
//
// @Injectable()
// export class AuthService {
//   // Create Auth0 web auth instance
//   // @TODO: Update environment variables and remove .example
//   // extension in src/environments/environment.ts.example
//   private Auth0 = new auth0.WebAuth({
//     clientID: environment.auth.CLIENT_ID,
//     domain: environment.auth.CLIENT_DOMAIN,
//     responseType: 'id_token token',
//     redirectUri: environment.auth.REDIRECT,
//     audience: environment.auth.AUDIENCE,
//     scope: 'openid profile email'
//   });
//   // Track whether or not to renew token
//   private authFlag = 'isLoggedIn';
//   // Create stream for token
//   token$ = new BehaviorSubject<string>(null);
//   // Create stream for user profile data
//   userProfile$ = new BehaviorSubject<any>(null);
//   // Authentication navigation
//   onAuthSuccessUrl = '/';
//   onAuthFailureUrl = '/user/login';
//   logoutUrl = environment.auth.LOGOUT_URL;
//   // Create observable of Auth0 parseHash method to gather auth results
//   parseHash$ = bindNodeCallback(this.Auth0.parseHash.bind(this.Auth0));
//   // Create observable of Auth0 checkSession method to
//   // verify authorization server session and renew tokens
//   checkSession$ = bindNodeCallback(this.Auth0.checkSession.bind(this.Auth0));
//
//   constructor(private router: Router) { }
//
//   login() {
//     this.Auth0.authorize();
//   }
//
//   handleLoginCallback() {
//     if (window.location.hash && !this.isAuthenticated) {
//       this.parseHash$().subscribe(
//         authResult => {
//           this.localLogin(authResult);
//           this.router.navigate([this.onAuthSuccessUrl]);
//         },
//         err => this.handleError(err)
//       );
//     }
//   }
//
//   private localLogin(authResult) {
//     // Observable of token
//     this.token$.next(authResult.idToken);
//     console.log("authResult: ", authResult);
//     // Emit value for user data subject
//     this.userProfile$.next(authResult.idTokenPayload);
//     // Set flag in local storage stating this app is logged in
//     localStorage.setItem(this.authFlag, JSON.stringify(true));
//     // this.amplifySignIn(authResult.idToken, authResult);
//   }
//
//   // amplifySignIn(token, data) {
//   //   Auth.federatedSignIn(
//   //     environment.auth.CLIENT_DOMAIN, // The Auth0 Domain,
//   //     {
//   //       token: token, // The id token from Auth0
//   //       // expires_at means the timestamp when the token provided expires,
//   //       // here we can derive it from the expiresIn parameter provided,
//   //       // then convert its unit from second to millisecond, and add the current timestamp
//   //       expires_at: data.expiresIn * 1000 // the expiration timestamp
//   //     },
//   //     {
//   //       // the user object, you can put whatever property you get from the Auth0
//   //       // for example:
//   //       name: data.idTokenPayload.name, // the user name
//   //       email: data.idTokenPayload.email, // Optional, the email address
//   //     },
//   //   ).then(cred => {
//   //     console.log(cred);
//   //   }).catch(error => console.log(error));
//   // }
//
//   get isAuthenticated(): boolean {
//     return JSON.parse(localStorage.getItem(this.authFlag));
//   }
//
//   renewAuth() {
//     if (this.isAuthenticated) {
//       this.checkSession$({}).subscribe(
//         authResult => this.localLogin(authResult),
//         err => {
//           localStorage.removeItem(this.authFlag);
//           this.router.navigate([this.onAuthFailureUrl]);
//         }
//       );
//     }
//   }
//
//   private localLogout() {
//     localStorage.setItem(this.authFlag, JSON.stringify(false));
//     this.token$.next(null);
//     this.userProfile$.next(null);
//   }
//
//   logout() {
//     this.localLogout();
//     // This does a refresh and redirects back to homepage
//     // Make sure you have the logout URL in your Auth0
//     // Dashboard Application settings in Allowed Logout URLs
//     this.Auth0.logout({
//       returnTo: this.logoutUrl,
//       clientID: environment.auth.CLIENT_ID
//     });
//   }
//
//   private handleError(err) {
//     if (err.error_description) {
//       console.error(`Error: ${err.error_description}`);
//     } else {
//       console.error(`Error: ${JSON.stringify(err)}`);
//     }
//   }
// }
