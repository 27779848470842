import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class VerifyFormService {
	public formList: Array<NgForm>;
	public isDirty: boolean;

	constructor() {
		this.formList = [];
		this.isDirty = false;
	}

	public getDirty(): boolean {
		return this.isDirty;
	}

	public addForm(f: NgForm) {
		let formIndex = this.formList.indexOf(f);
		if (f.dirty) {
			if (formIndex === -1) {
				this.formList.push(f);
				this.isDirty = true;
			}
		} else {
			if (formIndex > -1) {
				this.formList.splice(formIndex, 1);
				if (this.formList.length === 0) {
					this.isDirty = false;
				}
			}
		}
	}
}
