import { Injectable } from '@angular/core';
import { throwError, Observable, from } from 'rxjs';
import { API } from 'aws-amplify';
import { catchError, tap } from 'rxjs/operators';
import { sortByCreationDate } from '../../@core/services/common.service';

@Injectable({
	providedIn: 'root'
})
export class CampaignService {
	constructor() {}

	// CAMPAIGNS API
	getCampaigns() {
		return API.get('campaigns', `/campaigns`, {});
	}
	getCampaign(campaignId) {
		return API.get('campaigns', `/campaign/${campaignId}`, {});
	}
	createCampaign(campaign) {
		return API.post('campaigns', '/campaign', {
			body: campaign
		});
	}
	updateCampaign(campaign) {
		return API.put('campaigns', `/campaign/${campaign.id}`, { body: campaign });
	}
	removeCampaign(campaignId) {
		return API.del('campaigns', `/campaign/${campaignId}`, {});
	}

	sendEmails(sendData) {
		return API.post('actions', '/email/send-bulk', {
			body: sendData
		});
	}

	sendLiveCampaign(sendData) {
		return API.post('actions', '/email/live/send-campaign', {
			body: sendData
		});
	}

	getActions() {
		return from(API.get('actions', `/email/actions`, {}));
	}

	getEmailStats() {
		return from(API.get('actions', `/email/stats`, {}));
	}

	generateReportS3({ listId, actionId }) {
		return API.post('actions', `/email/report/generate`, {
			body: { listId, actionId }
		});
	}

	getStatsByCampaign(campaignId) {
		return from(API.get('actions', `/email/stats/campaign/${campaignId}`, {})).pipe(
			tap((result) => (result.length ? result.sort(sortByCreationDate) : [])),
			catchError((err) => {
				console.error(err);
				return throwError(err);
			})
		);
	}

	createActionItem(item) {
		return API.post('actions', '/email/action', {
			body: item
		});
	}
}
