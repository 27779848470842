import { Directive, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { VerifyFormService } from '../services/verify-form.service';

@Directive({
	selector: '[appVerifyNavigationFormCheck]'
})
export class VerifyNavigationDirective implements OnInit {
	public formSubscription: Subscription;
	@Input() verifyNavigationFormCheck: NgForm;
	constructor(public checkFormService: VerifyFormService) {}

	public ngOnInit() {
		this.formSubscription = this.verifyNavigationFormCheck.statusChanges.subscribe(() =>
			this.checkFormService.addForm(this.verifyNavigationFormCheck)
		);
	}
}
