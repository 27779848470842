import { Injectable } from '@angular/core';
import { throwError, Observable, from } from 'rxjs';
import { API } from 'aws-amplify';
import { filter, tap } from 'rxjs/operators';

const sortByCreationDate = (a, b) => (a.created < b.created ? 1 : a.created > b.created ? -1 : 0);

@Injectable({
	providedIn: 'root'
})
export class ListService {
	pageSize = 10;

	constructor() {}

	// LISTS API
	getLists() {
		return API.get('lists', `/lists`, {});
	}
	listsByClient(clientId) {
		return from(API.get('lists', `/lists`, {}));
		// .pipe(filter((value: any, key) => {
		// 	return value.clientId === clientId;
		// }), tap(result => result.length ? result.sort(sortByCreationDate) : []))
	}
	getList(listId) {
		return from(API.get('lists', `/list/${listId}`, {}));
	}
	createList(list) {
		return API.post('lists', '/list', {
			body: list
		});
	}
	updateList(list) {
		return from(API.put('lists', `/list/${list.id}`, { body: list }));
	}
	removeList(listId) {
		return from(API.del('lists', `/list/${listId}`, {}));
	}

	// RECORDS API
	getRecords(listId) {
		return from(API.get('records', `/records/list/${listId}`, {}));
	}

	getRecordsFull(listId) {
		return from(API.get('records', `/records/list-all/${listId}`, {}));
	}

	searchRecords(listId, email) {
		return API.get('records', `/list/${listId}/email/${email}`, {});
	}
	getRecord(listId, recordId) {
		return API.get('records', `/record/${recordId}/list/${listId}`, {});
	}
	createRecord(record) {
		return from(
			API.post('records', '/record', {
				body: record
			})
		);
	}
	updateRecord(record) {
		return from(API.put('records', `/record/${record.id}`, { body: record }));
	}
	removeRecord(listId, recordId) {
		return API.del('records', `/record/${recordId}/list/${listId}`, {});
	}
	removeAllRecords(listId) {
		return API.del('records', `/records/list/${listId}`, {});
	}

	getUnsubsByClientId(clientId) {
		return API.get('actions', `/email/blacklist/client/${clientId}`, {});
	}
}
