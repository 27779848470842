import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-default-layout',
	templateUrl: './default-layout.component.html',
	styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
	collapsedSideBar: boolean;

	constructor() {}

	ngOnInit() {}

	receiveCollapsed($event) {
		this.collapsedSideBar = $event;
	}
}
