import { Injectable } from '@angular/core';
// import { LoaderComponent } from './loader.component';

@Injectable({
	providedIn: 'root'
})
export class SpinnerService {
	loading: boolean;
	// dialogRef: MatDialogRef<LoaderComponent>;
	constructor() {} // private _dialog: MatDialog

	show(message: string = 'Please wait...'): void {
		setTimeout(() => {
			this.loading = true;
			console.log('IS_LOADING: ', this.loading);
			// this.dialogRef = this._dialog.open(LoaderComponent, {
			//     width: '80%',
			//     data: { 'message': message },
			//     closeOnNavigation: false
			// });
		});
	}

	hide() {
		// if (this.dialogRef) {
		//     this.dialogRef.close();
		//     this.loading = false;
		// }
		this.loading = false;
		console.log('IS_LOADING: ', this.loading);
	}
}
