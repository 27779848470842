import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { uuid } from '../../services/uuid';
import { catchError, map, tap } from 'rxjs/operators';
import { VariablesService } from './variables.service';
import { sortByIndexValue } from '../../@core/services/common.service';

@Injectable({
	providedIn: 'root'
})
export class VariablesStoreService {
	private readonly _variables = new BehaviorSubject<any[]>([]);
	readonly variables$ = this._variables.asObservable().pipe(tap((result) => result.sort(sortByIndexValue)));

	get variables(): any {
		return this._variables.getValue().sort(sortByIndexValue);
	}
	set variables(val: any) {
		this._variables.next(val);
	}

	constructor(public service: VariablesService) {}

	initData() {
		this.service.getVariables().subscribe(
			(data) => {
				this.variables = data;
			},
			(error) => console.error('ERROR: ', error)
		);
	}

	getVariable(id) {
		if (id !== 'new') {
			return this.service.getVariable(id);
		} else {
			return of({
				tag: null,
				variableName: null,
				fallbackValue: null
			});
		}
	}

	fetchVariables() {
		return this.service.getVariables();
	}

	createVariable(f: any) {
		const tmpId = uuid();
		const nVariable = {
			id: tmpId,
			tag: f.tag,
			variableName: f.variableName,
			fallbackValue: f.fallbackValue,
			indexValue: this.variables.length + 1
		};
		this.variables = [...this.variables, nVariable];
		return this.service.createVariable({ ...nVariable }).pipe(
			map((data: any) => {
				this.variables[this.variables.indexOf(this.variables.find((i) => i.id === tmpId))] = { ...data };
				this.variables = [...this.variables];
			}),
			catchError((err) => {
				this.removeVariable(tmpId, false);
				return throwError(err);
			})
		);
	}
	updateVariable(f: any) {
		const nVariable = {
			id: f.id,
			tag: f.tag,
			variableName: f.variableName,
			fallbackValue: f.fallbackValue
		};
		return this.service.updateVariable({ ...nVariable }).pipe(
			map((data: any) => {
				this.variables[this.variables.indexOf(this.variables.find((t) => t.id === f.id))] = {
					...nVariable
				};
				this.variables = [...this.variables];
			})
		);
	}
	removeVariable(id: string, serverRemove = true) {
		const variable = this.variables.find((c) => c.id === id);
		this.variables = this.variables.filter((i) => i.id !== id);
		if (serverRemove) {
			return this.service.removeVariable(id).pipe(
				catchError((err) => {
					console.error(err);
					this.variables = [...this.variables, variable];
					return throwError(err);
				})
			);
		}
	}

	buildColumns(keys) {
		// console.log('KEYS: ', keys);
		let columns = [];
		let props = this.variables
			.filter((i) => !['email'].includes(i.tag))
			.map((i) => ({
				name: i.tag,
				displayName: i.tag,
				indexValue: i.indexValue
			}));
		// console.log('PROPS: ', props);
		keys.filter((i) => !['email'].includes(i)).forEach((i) => {
			let item = props.find((t) => t.name === i);
			if (item) {
				columns.push({
					name: item.name,
					displayName: item.displayName,
					indexValue: item.indexValue
				});
			}
		});
		// console.log('COLUMNS: ', columns);
		return columns.sort((a, b) => a.indexValue - b.indexValue);
	}
}
