import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './@core/core.module';
// import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
// import { SpinnerOverlayModule } from './@shared/modules';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
	imports: [CoreModule, BrowserModule, BrowserAnimationsModule, HttpClientModule, NgxSpinnerModule, AppRoutingModule],
	declarations: [AppComponent],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule {}
