import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './@core/guards/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { InterceptorService } from "./@core/interceptors/interceptor.service";
import { DefaultLayoutComponent } from './@core/layouts/default-layout/default-layout.component';

const FEATURE_ROUTES: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule)
	},
	{
		path: 'campaigns',
		loadChildren: () => import('./pages/campaigns/campaigns.module').then((m) => m.CampaignsModule)
	},
	{
		path: 'clients',
		loadChildren: () => import('./pages/clients/clients.module').then((m) => m.ClientsModule)
	},
	{
		path: 'email-templates',
		loadChildren: () => import('./pages/email-template/email-template.module').then((m) => m.EmailTemplateModule)
	},
	{
		path: 'websites',
		loadChildren: () => import('./pages/websites/websites.module').then((m) => m.WebsitesModule)
	},
	{
		path: 'lists',
		loadChildren: () => import('./pages/lists/lists.module').then((m) => m.ListsModule)
	},
	{
		path: 'assets',
		loadChildren: () => import('./pages/assets/assets.module').then((m) => m.AssetsModule)
	},
	{
		path: 'variables',
		loadChildren: () => import('./pages/variables/variables.module').then((m) => m.VariablesModule)
	},
	{
		path: 'test-users',
		loadChildren: () => import('./pages/test-users/test-users.module').then((m) => m.TestUsersModule)
	}
];

const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule)
	},
	{
		path: '',
		component: DefaultLayoutComponent,
		children: FEATURE_ROUTES,
		canActivate: [AuthGuard]
	},
	{
		path: 'error',
		loadChildren: () => import('./pages/_basic/server-error/server-error.module').then((m) => m.ServerErrorModule)
	},
	{
		path: 'access-denied',
		loadChildren: () =>
			import('./pages/_basic/access-denied/access-denied.module').then((m) => m.AccessDeniedModule)
	},
	{
		path: 'not-found',
		loadChildren: () => import('./pages/_basic/not-found/not-found.module').then((m) => m.NotFoundModule)
	},
	// { path: '', redirectTo: '', pathMatch: 'prefix' },

	{ path: '**', redirectTo: '' }
];

const config: ExtraOptions = {
	useHash: false
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule],
	providers: [
		AuthGuard
		// {
		//     provide: HTTP_INTERCEPTORS,
		//     useClass: InterceptorService,
		//     multi: true
		// }
	]
})
export class AppRoutingModule {}
