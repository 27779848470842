import { Injectable } from '@angular/core';
import { VariablesStoreService } from '../pages/variables/variables-store.service';
import { Campaign, Client, EmailSendAction, EmailTemplate, Variable } from '../@shared/types';
import { TemplateStoreService } from '../pages/email-template/email-template.store';
import { ClientStoreService } from '../pages/clients/client.store';
import { CampaignStoreService } from '../pages/campaigns/campaign.store';
import * as _ from 'lodash';
import { ListService } from '../pages/lists/list.service';
import { BehaviorSubject } from 'rxjs';
import { sortByCreationDate } from '../@core/services/common.service';

@Injectable({ providedIn: 'root' })
export class GlobalStoreService {
	private readonly _testUsers = new BehaviorSubject<any[]>([]);
	readonly testUsers$ = this._testUsers.asObservable();

	get testUsers(): any {
		return this._testUsers.getValue();
	}
	set testUsers(val: any) {
		this._testUsers.next(val);
	}

	get parioAdminTesters(): any[] {
		return this.testUsers
			.filter((value: any, key) => {
				return ['Pario', 'MAR', 'MAR Graphics'].includes(this.getClientNameByID(value.clientId));
			})
			.map((i) => ({ display: `${i.email} (${this.getClientNameByID(i.clientId)})`, ...i }));
	}

	getParioAdminTesterById(userId) {
		return this.parioAdminTesters.find((i) => i.id === userId);
	}

	constructor(
		private clientStore: ClientStoreService,
		private templateStore: TemplateStoreService,
		private campaignStore: CampaignStoreService,
		private variablesStore: VariablesStoreService,
		public listApi: ListService
	) {}

	initData() {
		this.variablesStore.initData();
		this.clientStore.initData();
		this.campaignStore.initData();
		this.templateStore.initData();
		this.getTestUsers();
	}

	get variables(): Variable[] {
		return this.variablesStore.variables;
	}

	get campaigns(): Campaign[] {
		return this.campaignStore.campaigns;
	}

	get templates(): EmailTemplate[] {
		return this.templateStore.templates;
	}

	get clients(): Client[] {
		return this.clientStore.clients;
	}

	get actions(): EmailSendAction[] {
		return this.campaignStore.actions;
	}

	get totalActionCounts() {
		return this.campaignStore.totalActionCounts;
	}

	public getClientByID(clientId): Client {
		return this.clientStore.getClientByID(clientId);
	}

	public getClientNameByID(clientId): string {
		return this.clientStore.getClientByID(clientId) ? this.clientStore.getClientByID(clientId).clientName : '';
	}

	public getTestUsers() {
		this.listApi.getRecords('TEST_USERS').subscribe(
			(data) => {
				this.testUsers = data;
			},
			(error) => {
				console.error('ERROR: ', error);
			}
		);
	}
}
