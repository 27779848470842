import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, tap, catchError, timeout, concatMap, delay } from 'rxjs/operators';
import { Campaign, EmailSendAction } from '../../@shared/types';
import { sortByCreationDate } from '../../@core/services/common.service';
import { CampaignService } from './campaign.service';
import { uuid } from '../../services/uuid';

@Injectable({ providedIn: 'root' })
export class CampaignStoreService {
	private readonly _campaigns = new BehaviorSubject<Campaign[]>([]);
	readonly campaigns$ = this._campaigns.asObservable().pipe(tap((result) => result.sort(sortByCreationDate)));

	private readonly _actions = new BehaviorSubject<EmailSendAction[]>([]);
	readonly actions$ = this._actions
		.asObservable()
		.pipe(tap((result) => (result.length ? result.sort(sortByCreationDate) : [])));

	get actions(): EmailSendAction[] {
		return this._actions.getValue();
	}
	set totalActionCounts(actions) {
		console.log('EVENT ITEMS: ', actions);
		let counts = {
			total: 0,
			bounces: 0,
			complaints: 0
		};
		actions.forEach((data) => {
			counts.total = data.totalCount ? counts.total + data.totalCount : counts.total;
			counts.bounces = data.bounce ? counts.bounces + data.bounce : counts.bounces;
			counts.complaints = data.complaint ? counts.complaints + data.complaint : counts.complaints;
		});
		this._totalActionCounts.next(counts);
	}
	get totalActionCounts() {
		return this._totalActionCounts.getValue();
	}
	set actions(val: EmailSendAction[]) {
		this._actions.next(val);
	}

	private readonly _totalActionCounts = new BehaviorSubject<any>([]);
	readonly totalActionCounts$ = this._totalActionCounts.asObservable();

	constructor(private campaignService: CampaignService) {}

	initData() {
		this.campaignService.getActions().subscribe(
			(data) => {
				this.actions = data;
				this.totalActionCounts = data;
			},
			(error) => console.error('ERROR: ', error)
		);
	}

	get campaigns(): Campaign[] {
		return this._campaigns.getValue();
	}
	set campaigns(val: Campaign[]) {
		this._campaigns.next(val);
	}

	async create(c: any) {
		const tmpId = uuid();
		const nCampaign: any = {
			id: tmpId,
			campaignName: c.campaignName,
			fromEmail: c.fromEmail,
			fromEmailFriendlyName: c.fromEmailFriendlyName,
			listId: c.listId,
			clientId: c.clientId,
			description: c.description
		};

		this.campaigns = [...this.campaigns, nCampaign];

		try {
			const campaign = await this.campaignService.createCampaign({ ...nCampaign });
			campaign.properties =
				typeof campaign.properties === 'string' ? JSON.parse(campaign.properties) : campaign.properties;
			// we swap the local tmp record with the record from the server (id must be updated)
			const index = this.campaigns.indexOf(this.campaigns.find((t) => t.id === tmpId));
			this.campaigns[index] = {
				...campaign
			};
			this.campaigns = [...this.campaigns];
			return campaign;
		} catch (e) {
			// is server sends back an error, we revert the changes
			console.error(e);
			this.remove(tmpId, false);
		}
	}

	async update(c: any) {
		const uCampaign: any = {
			id: c.id,
			campaignName: c.campaignName,
			fromEmail: c.fromEmail,
			fromEmailFriendlyName: c.fromEmailFriendlyName,
			clientId: c.clientId,
			listId: c.listId,
			description: c.description
		};

		try {
			await this.campaignService.updateCampaign({ ...uCampaign });
			// we swap the local tmp record with the record from the server (id must be updated)
			const index = this.campaigns.indexOf(this.campaigns.find((t) => t.id === c.id));
			console.log('UPDATE_INDEX: ', index);
			this.campaigns[index] = {
				...uCampaign
			};
			this.campaigns = [...this.campaigns];
			console.log('UPDATE_CAMPAIGNS: ', this.campaigns);
		} catch (e) {
			// is server sends back an error, we revert the changes
			console.error(e);
		}
	}

	async remove(id: string, serverRemove = true) {
		// optimistic update
		const campaign = this.campaigns.find((c) => c.id === id);
		this.campaigns = this.campaigns.filter((i) => i.id !== id);

		if (serverRemove) {
			try {
				await this.campaignService.removeCampaign(id);
			} catch (e) {
				console.error(e);
				this.campaigns = [...this.campaigns, campaign];
			}
		}
	}
}
