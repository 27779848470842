import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { uuid } from '../../services/uuid';
import { EmailTemplateService } from './email-template.service';
import { sortByUpdateDate } from '../../@core/services/common.service';
import { EmailTemplate } from '../../@shared/types';

@Injectable({ providedIn: 'root' })
export class TemplateStoreService {
	private readonly _templates = new BehaviorSubject<EmailTemplate[]>([]);
	readonly templates$ = this._templates.asObservable().pipe(
		map((data) => data.filter((value: any, key) => value.templateType !== 'CLONEABLE')),
		tap((result) => result.sort(sortByUpdateDate))
	);
	readonly default_templates$ = this._templates.asObservable().pipe(
		map((data) => data.filter((value: any, key) => value.templateType === 'CLONEABLE')),
		tap((result) => result.sort(sortByUpdateDate))
	);

	constructor(private emailTemplateService: EmailTemplateService) {}

	initData() {
		this.emailTemplateService.getTemplates().subscribe(
			(data) => (this.templates = data),
			(error) => console.error('ERROR: ', error)
		);
	}

	get templates(): EmailTemplate[] {
		return this._templates.getValue();
	}
	set templates(val: EmailTemplate[]) {
		this._templates.next(val);
	}

	getTemplate(id): Observable<EmailTemplate> {
		return this.emailTemplateService.getTemplate(id);
	}

	fetchTemplates() {
		return this.emailTemplateService.getTemplates();
	}

	async createTemplate(tmp: any) {
		const tmpId = uuid();
		const nTemplate = {
			...tmp,
			id: tmpId
		};
		this.templates = [...this.templates, nTemplate];
		try {
			const template = await this.emailTemplateService.createTemplate({ ...nTemplate });
			const index = this.templates.indexOf(this.templates.find((i) => i.id === tmpId));
			this.templates[index] = { ...template };
			this.templates = [...this.templates];
			return template;
		} catch (e) {
			// console.error("ERROR: ", e);
			this.removeTemplate(tmpId, false);
			return JSON.parse(e);
		}
	}
	async updateTemplate(tmp: any) {
		const nTemplate = {
			...tmp
		};
		try {
			await this.emailTemplateService.updateTemplate({ ...nTemplate });
			const index = this.templates.indexOf(this.templates.find((i) => i.id === tmp.id));
			this.templates[index] = { ...nTemplate };
			this.templates = [...this.templates];
			console.log('UPDATE_TEMPLATES: ', this.templates);
			return nTemplate;
		} catch (e) {
			console.error('ERROR: ', e);
			return e;
		}
	}
	async removeTemplate(id: string, params: any, serverRemove = true) {
		const template = this.templates.find((c) => c.id === id);
		this.templates = this.templates.filter((i) => i.id !== id);
		if (serverRemove) {
			try {
				await this.emailTemplateService.removeTemplate(id, params);
			} catch (e) {
				console.error(e);
				this.templates = [...this.templates, template];
			}
		}
	}
}
