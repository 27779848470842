import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
	toasts: any[] = [];

	error(textOrTpl: string) {
		const options = { classname: 'bg-danger text-light', danger: true, autohide: false };
		this.toasts.push({ textOrTpl, ...options });
	}

	success(textOrTpl: string) {
		const options = { classname: 'bg-success text-light', delay: 5000, autohide: true, success: true };
		this.toasts.push({ textOrTpl, ...options });
	}

	default(textOrTpl: string) {
		let options = { delay: 5000, autohide: true };
		this.toasts.push({ textOrTpl, ...options });
	}

	show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
		this.toasts.push({ textOrTpl, ...options });
	}

	remove(toast) {
		console.log('REMOVE_ME!: ', toast);
		this.toasts = this.toasts.filter((t) => t !== toast);
	}
}
