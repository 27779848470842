import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { RouterModule } from '@angular/router';
import { AuthService } from './services/auth.service';
import { NgbDropdownModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from './components/spinner/spinner.service';
import { ToastsContainerComponent } from './components/toast-container/toasts-container.component';
import { VerifyNavigationDirective } from './directives/verify-navigation.directive';

@NgModule({
	declarations: [
		HeaderComponent,
		SidebarComponent,
		ToastsContainerComponent,
		DefaultLayoutComponent,
		VerifyNavigationDirective
	],
	imports: [CommonModule, NgbDropdownModule, NgbToastModule, RouterModule],
	exports: [DefaultLayoutComponent, ToastsContainerComponent],
	providers: [AuthService, SpinnerService]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}
}
