import { Injectable } from '@angular/core';
import { from, of } from 'rxjs';
import { API } from 'aws-amplify';

@Injectable({
	providedIn: 'root'
})
export class EmailTemplateService {
	constructor() {}

	sendEmail(sendData) {
		return API.post('actions', '/email/send', {
			body: sendData
		});
	}

	sendEmailWithAttachment(sendData) {
		return API.post('actions', '/email/send-with-attachment', {
			body: sendData
		});
	}

	// TEMPLATES API
	getTemplates() {
		return from(API.get('templates', `/templates`, {}));
	}
	getTemplate(templateId) {
		return from(API.get('templates', `/template/${templateId}`, {}));
	}
	createTemplate(template) {
		return API.post('templates', '/template', {
			body: template
		});
	}
	updateTemplate(template) {
		return API.put('templates', `/template/${template.id}`, { body: template });
	}
	removeTemplate(templateId, params) {
		return API.del('templates', `/template/${templateId}`, { body: params });
	}
}
