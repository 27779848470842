import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { from, of, throwError } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class VariablesService {
	constructor() {}

	// VARIABLES API
	getVariables() {
		return from(API.get('variables', `/variables`, {}));
	}
	getVariable(variableId) {
		return from(API.get('variables', `/variable/${variableId}`, {}));
	}
	createVariable(variable) {
		return from(
			API.post('variables', '/variable', {
				body: variable
			})
		);
	}
	updateVariable(variable) {
		return from(API.put('variables', `/variable/${variable.id}`, { body: variable }));
	}
	removeVariable(variableId) {
		return from(API.del('variables', `/variable/${variableId}`, {}));
	}
}
