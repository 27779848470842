import { Injectable } from '@angular/core';
import { ClientService } from './client.service';
import { Client } from '../../@shared/types';
import { sortByCreationDate, sortByUpdateDate } from '../../@core/services/common.service';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { uuid } from '../../services/uuid';
import { catchError, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class ClientStoreService {
	private readonly _clients = new BehaviorSubject<Client[]>([]);
	readonly clients$ = this._clients
		.asObservable()
		.pipe(tap((result) => (result.length ? result.sort(sortByCreationDate) : [])));

	get clients(): Client[] {
		return this._clients.getValue().sort(sortByCreationDate);
	}
	set clients(val: Client[]) {
		this._clients.next(val);
	}

	constructor(private service: ClientService) {}

	initData() {
		this.service.getClients().subscribe(
			(data) => (this.clients = data),
			(error) => console.error('ERROR: ', error)
		);
	}

	getClient(id) {
		if (id !== 'new') {
			return this.service.getClient(id);
		} else {
			return of({
				clientName: null,
				clientType: null,
				clientFromEmail: null,
				description: null,
				testRecords: []
			});
		}
	}

	getClientByID(clientId): Client {
		return this.clients.filter((value: any, key) => {
			return value.id === clientId;
		})[0];
	}

	getParioTesters(): any[] {
		return _.flatten(
			this.clients
				.filter((value: any, key) => {
					return ['Pario', 'MAR'].includes(value.clientName);
				})
				.map((i) => i.testRecords.map((i2) => ({ display: `${i2.email} (${i.clientName})`, ...i2 })))
		);
	}

	fetchClients() {
		return this.service.getClients();
	}
	createClient(f: any) {
		const tmpId = uuid();
		const nClient = {
			id: tmpId,
			clientName: f.clientName,
			clientType: f.clientType,
			clientFromEmail: f.clientFromEmail,
			description: f.description,
			testRecords: f.testRecords
		};
		this.clients = [...this.clients, nClient];
		return this.service.createClient({ ...nClient }).pipe(
			map((data: any) => {
				this.clients[this.clients.indexOf(this.clients.find((i) => i.id === tmpId))] = { ...data };
				this.clients = [...this.clients];
			}),
			catchError((err) => {
				this.removeClient(tmpId, false);
				return throwError(err);
			})
		);
	}
	updateClient(f: any) {
		const nClient = {
			id: f.id,
			clientName: f.clientName,
			clientType: f.clientType,
			clientFromEmail: f.clientFromEmail,
			description: f.description,
			testRecords: f.testRecords
		};
		// console.log('nClient: ', nClient);
		return this.service.updateClient({ ...nClient }).pipe(
			map((data: any) => {
				this.clients[this.clients.indexOf(this.clients.find((i) => i.id === f.id))] = { ...nClient };
				this.clients = [...this.clients];
			})
		);
	}
	updateClientTestRecords(f: any) {
		let client = this.getClientByID(f.clientId);
		const nClient = {
			id: client.id,
			clientName: client.clientName,
			clientType: client.clientType,
			clientFromEmail: client.clientFromEmail,
			description: client.description,
			testRecords: f.testRecords
		};
		// console.log('nClient: ', nClient);
		return this.service.updateClient({ ...nClient }).pipe(
			map((data: any) => {
				this.clients[this.clients.indexOf(this.clients.find((i) => i.id === f.id))] = { ...nClient };
				this.clients = [...this.clients];
			})
		);
	}
	removeClient(id: string, serverRemove = true) {
		const client = this.clients.find((c) => c.id === id);
		this.clients = this.clients.filter((i) => i.id !== id);
		if (serverRemove) {
			return this.service.removeClient(id).pipe(
				catchError((err) => {
					console.error(err);
					this.clients = [...this.clients, client];
					return throwError(err);
				})
			);
		}
	}
}
