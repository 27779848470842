import { Component, OnInit } from '@angular/core';
import { AuthService } from './@core/services/auth.service';
import { GlobalStoreService } from './services/global-store.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	isLoggedIn = false;
	user: { id: string; username: string; email: string };

	constructor(private _auth: AuthService, private global: GlobalStoreService) {}

	ngOnInit() {
		this._auth.isLoggedIn$.subscribe((isLoggedIn) => {
			this.isLoggedIn = isLoggedIn;
			if (isLoggedIn) {
				this.global.initData();
			}
		});

		this._auth.auth$.subscribe(({ id, username, email }) => {
			this.user = { id, username, email };
		});
	}
}
