import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import Auth from '@aws-amplify/auth';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(private _router: Router) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return Auth.currentAuthenticatedUser()
			.then(() => true)
			.catch(() => {
				this._router.navigate(['/auth/login']);
				return false;
			});
	}
}

// import { Injectable } from '@angular/core';
// import {
//   Router,
//   CanActivate,
//   ActivatedRouteSnapshot,
//   RouterStateSnapshot
// } from '@angular/router';
// import { AuthService } from '../services/auth.service';
// import { Observable } from 'rxjs';
//
// @Injectable()
// export class AuthGuard implements CanActivate {
//
//   constructor(
//     private auth: AuthService,
//     private router: Router
//   ) { }
//
//   canActivate(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): Observable<boolean> | Promise<boolean> | boolean {
//     if (this.auth.isAuthenticated) {
//       return true;
//     }
//     this.router.navigate([this.auth.onAuthFailureUrl]);
//     return false;
//   }
//
// }
