import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import Auth from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	public pushRightClass: string;
	profileJson: string = null;
	user: any;
	public isLoggedIn = false;
	avatar: any;
	profile: any = {};

	constructor(public _router: Router, public _auth: AuthService) {
		this._router.events.subscribe((val) => {
			if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
				this.toggleSidebar();
			}
		});

		this._auth.isLoggedIn$.subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn));
	}

	async ngOnInit() {
		this.pushRightClass = 'push-right';

		try {
			this.profile = await Auth.currentUserInfo();
			console.log('USER: ', this.profile);
			if (this.profile && this.profile.attributes.profile) {
				const avatar = this.profile.attributes.profile;
				this.avatar = (await Storage.vault.get(avatar)) as string;
			}
		} catch (error) {
			console.log('no session: ', error);
		}
		// this.userService.getUsers()
		//     .pipe(takeUntil(this.destroy$))
		//     .subscribe((users: any) => this.user = users.nick);
	}

	isToggled(): boolean {
		const dom: Element = document.querySelector('body');
		return dom.classList.contains(this.pushRightClass);
	}

	toggleSidebar() {
		const dom: any = document.querySelector('body');
		dom.classList.toggle(this.pushRightClass);
	}

	rltAndLtr() {
		const dom: any = document.querySelector('body');
		dom.classList.toggle('rtl');
	}

	onLoggedout() {
		localStorage.removeItem('isLoggedin');
	}

	logout() {
		console.log('DO_LOGOUT');
		this._auth.signOut().then(() => this._router.navigate(['auth/login']));
	}
}
